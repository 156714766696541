/* ConfirmationModal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.deleteModal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
  color: black;
}

.deleteModal h2 {
  margin-top: 0;
}

.modalButtons {
  display: flex;
  justify-content: end;
}

.cancelButton,
.confirmButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  background: #ccc;
}

.confirmButton {
  background: #d9534f;
  color: #fff;
}
