body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /* background-color: #f4f4f9; */
}

/* scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 20px;
  background: #0805023a;
}

::-webkit-scrollbar-thumb:hover {
  background: #0805023a;
}

#sidebar-container::-webkit-scrollbar {
  display: none !important;
}

/* save button */
.save-button {
  color: white;
  width: 150px;
}

.icon-container {
  position: absolute;
  bottom: 5px;
  right: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon {
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.icon.disabled {
  opacity: 0.5;
}

.dndflow {
  /* flex-direction: column; */
  display: flex;
  height: calc(100vh - 4rem);
  overflow: hidden;
}
.main-content-wrapper {
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
}

.main-content-wrapper.shrink {
  width: calc(100% - 320px); /* Shrink the main content when sidebar is open */
}

.right-sidebar {
  transition: width 0.5s ease-in-out;
}

.right-sidebar.open {
  width: 250px; /* Sidebar width when opened */
}

.sidebar-toggle-button {
  position: absolute;
  top: 50%;
  right: 0;
  background: #e7e1e1;
  z-index: 100;
  padding: 0.5rem;
  border-radius: 0.5rem 0 0 0.5rem;
  cursor: pointer;
}

.sidebar-content {
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 0.8rem;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 10px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-block: 10px;
  max-width: 100%;
}

.dndnode {
  border-width: 1.3px !important;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndnode_input {
  border: 1.3px solid rgb(0, 81, 255) !important;
}

.dndnode_conditional {
  border: 1.3px solid rgb(41, 94, 46) !important;
}

.input-box {
  height: 20px;
  font-size: 12px;
  width: 97% !important;
}

.input-box::placeholder {
  font-size: 10px;
}

.input-box:focus-visible {
  outline: none;
}

.dropdown-box {
  height: 2.5rem;
  font-size: 12px;
}

.dropdown-box:focus-visible {
  outline: none;
}

.dndflow .dndnode.output {
  border: 1.3px solid #ff0072;
}

/* Add this CSS to your styles.css or a relevant CSS file */

.dropdown-container label {
  font-size: 14px;
  color: #333;
  padding-right: 10px;
}

.dropdown-container select {
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
}

.sidebar-container {
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  width: 30% !important;
}

.right-sidebar {
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
}

.tooltip {
  position: relative;
  display: inline-block;
  padding: 0;
}

.tooltip .tooltip-text {
  visibility: visible;
  /* width: 400px; */
  background-color: #282424;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 35px; /* Position the tooltip above the icon */
  left: 50px;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.5s;
  font-size: 12px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.collapse-title {
  min-height: 2rem !important;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.6s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* styles.css */

.react-flow__node-custom {
  z-index: 99 !important;
  /* Ensure nodes are on top of edges */
}

.react-flow__edge {
  z-index: 1;
  /* Ensure edges are below nodes */
}

.react-flow__panel.right {
  display: none;
}

.nodrag {
  user-select: none;
}

.nopan {
  pointer-events: none;
}

.react-flow__node.input {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
}

.react-flow__node.output {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
}

.reactflow-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
}

.rc-input-number-handler-wrap {
  display: none !important;
}

.rct-node-icon {
  display: none !important;
}

.rct-title {
  color: black !important;
}
.react-checkbox-tree label {
  max-width: 500px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3b5998;
  /* Dull blue color */
  padding: 10px 30px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-left {
  font-size: 26px;
  /* Increase font size */
  font-weight: bold;
  /* Make the font bold */
}

.create-button {
  background-color: #ff9800;
  /* Change button color to a noticeable orange */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  /* Increase font size */
  transition: background-color 0.3s;
}

.create-button:hover {
  background-color: #e68900;
  /* Darken the button on hover */
}

/* tabs */
.tabs-boxed {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.tab {
  cursor: pointer;
  border-radius: 0px !important;
  margin: 0 5px;
  font-weight: 600;
  color: #667085 !important;
}

.active-tab {
  border-bottom: 2px solid #4a387d;
  color: #4a387d !important;
}

/* custom node css */
.beta-badge-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 4rem;
  width: 4rem;
  overflow: hidden;
  border-top-right-radius: 0.5rem;
}

.beta-badge-content {
  margin-top: 0.5rem;
  width: 6rem;
  --tw-rotate: 45deg;
  transform: translate(0, 0) rotate(45deg) skew(0) skewY(0) scaleX(1) scaleY(1);
  background-color: #dbeafe;
  text-align: center;
  font-size: 0.65rem;
  line-height: 1rem;
  font-weight: 600;
  color: #2563eb;
}

.content {
  padding: 20px;
  text-align: center;
}

.heading {
  font-size: 32px;
  margin-bottom: 30px;
  color: #333;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-block: 20px;
}

.grid-item {
  cursor: pointer;
  width: 300px;
  /* Fixed width */
  height: 250px;
  /* Fixed height */
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  position: relative;
  /* Added for positioning the delete icon */
}

.grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.flow-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 11px;
  display: none;
  cursor: pointer;
}

.grid-item:hover .delete-icon {
  display: block;
}

.no-flows-message {
  text-align: center;
  width: 100%;
  font-size: 18px;
  color: #555;
  margin-top: 20px;
}

.flow-title {
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #555;
}

/* Buttons */
button {
  margin: 10px;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it is above other elements */
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: semi-transparent background */
  width: 100vw;
  height: 100vh;
}
