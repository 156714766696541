@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
  --background: #232d39;
  --text: rgb(245, 245, 245);
  --border: rgb(245, 245, 245);
  --modal: #2a3749;
}

.light {
  --background: rgb(245, 245, 245);
  --text: rgb(0, 0, 0);
  --border: rgb(0, 0, 0);
  --modal: #ffffff;
}

.img_inverse_color {
  filter: invert(100%) brightness(100%);
}
